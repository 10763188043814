<template>
  <div class="box">
    <a-spin :spinning="loading">
      <div class="card-title">
        卡片数据统计
      </div>

      <a-row :gutter="24">
        <a-col span="4">
          <div
            class="chart"
            ref="cardsCountStatisticsCarriersSummaryChart"
          />
        </a-col>

        <a-col span="4">
          <div
            class="chart"
            ref="cardsCountStatisticsChinaMobileChart"
          />
        </a-col>

        <a-col span="4">
          <div
            class="chart"
            ref="cardsCountStatisticsChinaUnicomChart"
          />
        </a-col>

        <a-col span="4">
          <div
            class="chart"
            ref="cardsCountStatisticsChinaTelecomChart"
          />
        </a-col>

        <a-col span="4">
          <div
            class="chart"
            ref="cardsCountStatisticsChinaBroadnetChart"
          />
        </a-col>

        <a-col span="4">
          <div
            class="chart"
            ref="cardsCountStatisticsInternationalCarrierChart"
          />
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { findHomeCardsCountStatistics } from '@/api/home'
import { formatBigNumber } from '@/utils/filter'

export default {
  name: 'CardsCountStatistics',
  data() {
    this.handleWindowResize = this.$lodash.throttle(this.handleWindowResize, 1000)
    return {
      loading: true,
      noDataColor: ['#e4e4e4'],
      carrierColor: ['#1d84b0', '#d0241f', '#99a5ee', '#2b4eff', '#00f9e5'],
      cardLifeCycleColor: [
        '#347808',
        '#20df85',
        '#1878a6',
        '#ebd6aa',
        '#b01212'
      ],
      carrierSummaryChat: null,
      chinaMobileChart: null,
      chinaUnicomChart: null,
      chinaTelecomChart: null,
      chinaBroadnetChart: null,
      internationalCarrierChart: null,
      data: [] // 图表数据
    }
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    this.fetchData()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  computed: {
    // 运营商汇总图表的配置项和数据
    carriersSummaryChartOption() {
      return {
        title: {
          text: '{a|}{b|}',
          left: 'center',
          top: '85',
          textStyle: {
            fontSize: '12px',
            rich: {
              b: {
                backgroundColor: {
                  image: require('@/assets/images/cards_icon.svg')
                },
                height: 30,
                width: 30
              }
            }
          },
          subtext: formatBigNumber(this.data.summary.total_count),
          subtextStyle: {
            color: 'blank',
            fontSize: '14'
          }
        },
        tooltip: {
          trigger: 'item',
          position: function(point, params, dom, rect, size) {
            // size为当前窗口大小
            if ((size.viewSize[0] / 2) >= point[0]) {
              // 其中point为当前鼠标的位置
              return [point[0] + 50, '10%']
            } else {
              // 其中point为当前鼠标的位置
              return [point[0] - 200, '10%']
            }
          },
          formatter: function(params) {
            if (params.value === 0) {
              return '暂无数据'
            }
            return `${params.marker} ${params.data.original_name}: ${formatBigNumber(params.value)} (${params.percent}%)`
          }
        },
        legend: {
          orient: 'horizontal',
          height: '2px',
          bottom: 0,
          icon: 'circle',
          y: '220'
        },
        color: this.carrierColor,
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['65', '90'],
            center: ['50%', '110'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false
            },
            data: []
          }
        ]
      }
    },

    chinaMobileChartOption() {
      return {
        title: {
          text: '{a|}{b|}',
          left: 'center',
          top: '85',
          textStyle: {
            fontSize: '12px',
            rich: {
              b: {
                backgroundColor: {
                  image: require('@/assets/images/china_mobile_icon.svg')
                },
                height: 30,
                width: 30
              }
            }
          },
          subtext: formatBigNumber(this.data.china_mobile.total_count),
          subtextStyle: {
            color: 'blank',
            fontSize: '14'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function(params) {
            if (params.value === 0) {
              return '暂无数据'
            }
            return `${params.marker} ${params.data.original_name}: ${formatBigNumber(params.value)} (${params.percent}%)`
          }
        },
        legend: {
          orient: 'horizontal',
          height: '2px',
          bottom: 0,
          icon: 'circle',
          y: '220'
        },
        color: this.cardLifeCycleColor,
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['65', '90'],
            center: ['50%', '110'],
            avoidLabelOverlap: false,
            label: {
              show: false
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            data: []
          }
        ]
      }
    },

    chinaUnicomChartOption() {
      return {
        title: {
          text: '{a|}{b|}',
          left: 'center',
          top: '85',
          textStyle: {
            fontSize: '12px',
            rich: {
              b: {
                backgroundColor: {
                  image: require('@/assets/images/china_unicom_icon.svg')
                },
                height: 30,
                width: 30
              }
            }
          },
          subtext: formatBigNumber(this.data.china_unicom.total_count),
          subtextStyle: {
            color: 'blank',
            fontSize: '14'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function(params) {
            if (params.value === 0) {
              return '暂无数据'
            }
            return `${params.marker} ${params.data.original_name}: ${formatBigNumber(params.value)} (${params.percent}%)`
          }
        },
        legend: {
          orient: 'horizontal',
          height: '2px',
          bottom: 0,
          icon: 'circle',
          y: '220'
        },
        color: this.cardLifeCycleColor,
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['65', '90'],
            center: ['50%', '110'],
            avoidLabelOverlap: false,
            label: {
              show: false
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            data: []
          }
        ]
      }
    },

    chinaTelecomChartOption() {
      return {
        title: {
          text: '{a|}{b|}',
          left: 'center',
          top: '85',
          textStyle: {
            fontSize: '12px',
            rich: {
              b: {
                backgroundColor: {
                  image: require('@/assets/images/china_telecom_icon.svg')
                },
                height: 30,
                width: 30
              }
            }
          },
          subtext: formatBigNumber(this.data.china_telecom.total_count),
          subtextStyle: {
            color: 'blank',
            fontSize: '14'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function(params) {
            if (params.value === 0) {
              return '暂无数据'
            }
            return `${params.marker} ${params.data.original_name}: ${formatBigNumber(params.value)} (${params.percent}%)`
          }
        },
        legend: {
          orient: 'horizontal',
          height: '2px',
          bottom: 0,
          icon: 'circle',
          y: '220'
        },
        color: this.cardLifeCycleColor,
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['65', '90'],
            center: ['50%', '110'],
            avoidLabelOverlap: false,
            label: {
              show: false
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            data: []
          }
        ]
      }
    },

    chinaBroadnetChartOption() {
      return {
        title: {
          text: '{a|}{b|}',
          left: 'center',
          top: '85',
          textStyle: {
            fontSize: '12px',
            rich: {
              b: {
                backgroundColor: {
                  image: require('@/assets/images/china_broadnet_icon.png')
                },
                height: 30,
                width: 30
              }
            }
          },
          subtext: formatBigNumber(this.data.china_broadnet.total_count),
          subtextStyle: {
            color: 'blank',
            fontSize: '14'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function(params) {
            if (params.value === 0) {
              return '暂无数据'
            }
            return `${params.marker} ${params.data.original_name}: ${formatBigNumber(params.value)} (${params.percent}%)`
          }
        },
        legend: {
          orient: 'horizontal',
          height: '2px',
          bottom: 0,
          icon: 'circle',
          y: '220'
        },
        color: this.cardLifeCycleColor,
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['65', '90'],
            center: ['50%', '110'],
            avoidLabelOverlap: false,
            label: {
              show: false
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            data: []
          }
        ]
      }
    },

    internationalCarrierChartOption() {
      return {
        title: {
          text: '{a|}{b|}',
          left: 'center',
          top: '85',
          textStyle: {
            fontSize: '12px',
            rich: {
              b: {
                backgroundColor: {
                  image: require('@/assets/images/international_carrier_icon.svg')
                },
                height: 30,
                width: 30
              }
            }
          },
          subtext: formatBigNumber(this.data.international_carrier.total_count),
          subtextStyle: {
            color: 'blank',
            fontSize: '14'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function(params) {
            if (params.value === 0) {
              return '暂无数据'
            }
            return `${params.marker} ${params.data.original_name}: ${formatBigNumber(params.value)} (${params.percent}%)`
          }
        },
        legend: {
          orient: 'horizontal',
          height: '2px',
          bottom: 0,
          icon: 'circle',
          y: '220'
        },
        color: this.cardLifeCycleColor,
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['65', '90'],
            center: ['50%', '110'],
            avoidLabelOverlap: false,
            label: {
              show: false
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            data: []
          }
        ]
      }
    }
  },
  methods: {
    handleWindowResize() {
      this.carrierSummaryChat && this.carrierSummaryChat.resize()
      this.chinaMobileChart && this.chinaMobileChart.resize()
      this.chinaUnicomChart && this.chinaUnicomChart.resize()
      this.chinaTelecomChart && this.chinaTelecomChart.resize()
      this.chinaBroadnetChart && this.chinaBroadnetChart.resize()
      this.internationalCarrierChart && this.internationalCarrierChart.resize()
    },

    // 根据数据绘制运营商汇总图表
    drawCarriersSummaryChart() {
      this.carrierSummaryChat ||= this.$echarts.init(this.$refs.cardsCountStatisticsCarriersSummaryChart)
      this.carriersSummaryChartOption.series[0].data = this.formatChartSeriesData(this.data.summary)
      // 使用刚指定的配置项和数据显示图表
      this.carrierSummaryChat.setOption(this.setChartOption(this.carriersSummaryChartOption, this.data.summary))
    },

    // 根据数据绘制移动图表
    drawChinaMobileChart() {
      this.chinaMobileChart ||= this.$echarts.init(this.$refs.cardsCountStatisticsChinaMobileChart)
      this.chinaMobileChartOption.series[0].data = this.formatChartSeriesData(this.data.china_mobile)
      // 使用刚指定的配置项和数据显示图表
      this.chinaMobileChart.setOption(this.setChartOption(this.chinaMobileChartOption, this.data.china_mobile))
    },

    // 根据数据绘制联通图表
    drawChinaUnicomChart() {
      this.chinaUnicomChart ||= this.$echarts.init(this.$refs.cardsCountStatisticsChinaUnicomChart)
      this.chinaUnicomChartOption.series[0].data = this.formatChartSeriesData(this.data.china_unicom)
      // 使用刚指定的配置项和数据显示图表
      this.chinaUnicomChart.setOption(this.setChartOption(this.chinaUnicomChartOption, this.data.china_unicom))
    },

    // 根据数据绘制电信图表
    drawChinaTelecomChart() {
      this.chinaTelecomChart ||= this.$echarts.init(this.$refs.cardsCountStatisticsChinaTelecomChart)
      this.chinaTelecomChartOption.series[0].data = this.formatChartSeriesData(this.data.china_telecom)
      // 使用刚指定的配置项和数据显示图表
      this.chinaTelecomChart.setOption(this.setChartOption(this.chinaTelecomChartOption, this.data.china_telecom))
    },

    // 根据数据绘制广电图表
    drawChinaBroadnetChart() {
      this.chinaBroadnetChart ||= this.$echarts.init(this.$refs.cardsCountStatisticsChinaBroadnetChart)
      this.chinaBroadnetChartOption.series[0].data = this.formatChartSeriesData(this.data.china_broadnet)
      // 使用刚指定的配置项和数据显示图表
      this.chinaBroadnetChart.setOption(this.setChartOption(this.chinaBroadnetChartOption, this.data.china_broadnet))
    },

    // 根据数据绘制国际运营商图表
    drawInternationalCarrierChart() {
      this.internationalCarrierChart ||= this.$echarts.init(this.$refs.cardsCountStatisticsInternationalCarrierChart)
      this.internationalCarrierChartOption.series[0].data = this.formatChartSeriesData(this.data.international_carrier)
      // 使用刚指定的配置项和数据显示图表
      this.internationalCarrierChart.setOption(this.setChartOption(this.internationalCarrierChartOption, this.data.international_carrier))
    },

    formatChartSeriesData(carrierData) {
      if (carrierData.total_count === 0) {
        return [{ value: 0, name: '暂无数据' }]
      } else {
        return this.$lodash.map(carrierData.statistics, item => {
          item.original_name = item.name
          item.name = `${item.name} ${formatBigNumber(item.value)}`
          return item
        })
      }
    },

    setChartOption(option, carrierData) {
      if (carrierData.total_count === 0) {
        option.color = this.noDataColor
      }
      return option
    },

    fetchData() {
      this.loading = true
      findHomeCardsCountStatistics(this.id, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.drawCarriersSummaryChart()
          this.drawChinaMobileChart()
          this.drawChinaUnicomChart()
          this.drawChinaTelecomChart()
          this.drawChinaBroadnetChart()
          this.drawInternationalCarrierChart()
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 300px;
  .card-title {
    color: @primary-color;
    font-size: 16px;
  }

  .chart {
    height: 300px;
  }
}
</style>
