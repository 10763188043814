const getters = {
  unreadNotificationCount: state => state.notification.unreadCount,
  isNeedCheckAutoAlertNotification: state => state.notification.isNeedCheckAutoAlertNotification,
  autoAlertNotificationId: state => state.notification.autoAlertNotificationId,
  userId: state => state.user.id,
  userFullName: state => state.user.fullName,
  userRole: state => state.user.role,
  userAgentId: state => state.user.agent_id,
  userAgentType: state => state.user.agent_type,
  userRegisteredAgentId: state => state.user.registered_agent_id,
  registeredAgentName: state => state.user.registered_agent_name,
  permissions: state => state.user.permissions,
  isShowAgreement: state => state.user.is_show_agreement,
  dynamicBreadCrumbs: state => state.app.dynamicBreadCrumbs,
  currentMenus: state => state.app.currentMenus
}

export default getters
