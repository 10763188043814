import router from '@/router'
import store from './store'

// 加载进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { setPageTitle, setActiveMenu, setBreadCrumbs, setFavicon } from '@/utils/dom_util'
import { getToken } from '@/utils/token'
import { hasPermission } from '@/utils/permission'

const route404Path = '/404'
const routeLoginPath = '/login'
const defaultRoutePath = '/'
// 免登录路由名称白名单
const routerWhiteList = ['login', 'switch_user', 'register', 'qrc_login_callback']

// 注册分销商路由
const registeredAgentRouterList = ['/registered_agents/home', '/registered_agents/enterprise_qualification/edit', '/registered_agents/id']
// 注册分销商主页路由
const registeredAgentHomeRouter = '/registered_agents/home'

// OpenUser主页路由
const openUserHomeRouter = '/oc_sim_cards'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach((to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  setPageTitle(to)
  // 设置菜单选中(导航菜单高亮)
  setActiveMenu(to)
  // 设置面包屑
  setBreadCrumbs(to)

  // 超级登录不受当前是否登录影响(需要替换token)
  if (to.name === 'switch_user') {
    next()
    return
  }

  // 未登录情况
  if (!getToken()) {
    if (routerWhiteList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({
        path: routeLoginPath,
        query: { redirect: to.fullPath }
      })
    }
    return
  }

  // 已登录
  if (to.path === routeLoginPath) {
    next({ path: defaultRoutePath })
    return
  }

  // 没有获取身份及权限信息，调用api获取
  if (store.getters.permissions.length === 0) {
    // 获取个人信息及权限
    store.dispatch('FindUserInfo')
      .then(() => {
        if (store.getters.userRole === 'registered_agent' && to.path === '/') {
          next({ path: registeredAgentHomeRouter })
          return
        }
        if (store.getters.userRole === 'open_user' && to.path === '/') {
          next({ path: openUserHomeRouter })
          return
        }
        if (store.getters.userRole !== 'registered_agent' && registeredAgentRouterList.includes(to.path)) {
          next({ path: '/' })
          return
        }
        // 需要验证权限，进行验证
        if (hasPermission(to.meta.permission)) {
          next()
        } else {
          next({
            path: route404Path
          })
        }
      })
      .catch(() => {
        // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
        store.dispatch('ClearLoginInfo').then(() => {
          next({
            path: routeLoginPath,
            query: { redirect: to.fullPath }
          })
        })
      })
  } else if (to.meta.permission) {
    // 需要验证权限，进行验证
    if (hasPermission(to.meta.permission)) {
      next()
    } else {
      next({
        path: route404Path
      })
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  // 设置网站图标 分销商单独设置网站图标
  if (store.getters.userRole === 'top_agent') {
    setFavicon('favicon_iot.png')
  } else {
    setFavicon('favicon.png')
  }

  // finish progress bar
  NProgress.done()
})
