<template>
  <div />
</template>
<script>

import { findAppVersion } from '@/api/app'

export default {
  name: 'CheckAppVersion',
  data() {
    return {
      isWarningConfirmShow: false,
      versionCheckTimer: null // 检测版本更新定时器
    }
  },
  computed: {
    appVersion() {
      return process.env.VUE_APP_VERSION
    }
  },
  created() {
    // this.loopCheckAppVersion()
  },
  destroyed() {
    clearInterval(this.versionCheckTimer)
  },
  methods: {
    // 轮询检测新的通知消息
    loopCheckAppVersion() {
      // 开发环境不需要检测
      if (process.env.NODE_ENV === 'development') {
        return
      }
      this.versionCheckTimer = setInterval(this.checkAppVersion, 60000)
    },

    // 稍后刷新
    handleRefreshLater() {
      clearInterval(this.versionCheckTimer)
      this.isWarningConfirmShow = false
      setTimeout(this.checkAppVersion, 20000)
    },

    // 检查 app 版本
    checkAppVersion() {
      // 已经提示有新版本，则不再提示
      if (this.isWarningConfirmShow) {
        return
      }

      findAppVersion().then((res) => {
        if (this.appVersion !== res.version) {
          const vm = this
          vm.isWarningConfirmShow = true
          vm.$confirm({
            title: '检测到新版本！',
            content: (
              <div>
                <p>检测到新版本，请刷新页面获取最新版本的内容！</p>
              </div>
            ),
            okText: '现在刷新',
            cancelText: '稍后刷新',
            icon: 'warning',
            onOk() {
              window.location.reload()
            },
            onCancel() {
              vm.handleRefreshLater()
            }
          })
        }
      })
    }
  }
}
</script>
