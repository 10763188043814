// 查询用户企业资质
import request from '@/utils/request'

export function FindEnterpriseQualification(params) {
  return request({
    url: `/enterprise_qualifications`,
    method: 'get',
    params: params
  })
}
// 获取注册分销商企业资质
export function FindRegisteredAgentEnterpriseQualification(params) {
  return request({
    url: `/enterprise_qualifications/registered_agent`,
    method: 'get',
    params: params
  })
}

// 创建提交注册分销商企业资质信息
export function createRegisteredAgentEnterpriseQualification(data) {
  return request({
    url: `/enterprise_qualifications/registered_agent`,
    method: 'post',
    data
  })
}

// 更新注册分销商的企业资质信息
export function updateRegisteredAgentEnterpriseQualification(data) {
  return request({
    url: `/enterprise_qualifications/registered_agent`,
    method: 'put',
    data
  })
}

// 更新注册分销商企业资质前,获取企业资质展示信息
export function findRegisteredAgentEnterpriseQualificationpUdateForm(params) {
  return request({
    url: `/enterprise_qualifications/registered_agent/form`,
    method: 'get',
    params: params
  })
}
