import { pullNotifications } from '@/api/notification'

const notification = {
  state: {
    unreadCount: 0, // 未读消息个数
    isNeedCheckAutoAlertNotification: false, // 是否需要检测需要自动弹出的通知消息
    autoAlertNotificationId: 0 // 自动弹出的通知消息id(弹出即已读, 其他展示消息的页面可根据该id自动消除未读状态)
  },

  mutations: {
    SET_UNREAD_COUNT: (state, unreadCount) => {
      state.unreadCount = unreadCount
    },

    SET_IS_NEED_CHECK_AUTO_ALERT_NOTIFICATION: (state, isNeedCheckAutoAlertNotification) => {
      state.isNeedCheckAutoAlertNotification = isNeedCheckAutoAlertNotification
    },

    SET_AUTO_ALERT_NOTIFICATION_ID: (state, autoAlertNotificationId) => {
      state.autoAlertNotificationId = autoAlertNotificationId
    },

    CLEAR_NOTIFICATION_DATA: (state) => {
      state.unreadCount = 0
      state.isNeedCheckAutoAlertNotification = false
      state.autoAlertNotificationId = 0
    }
  },

  actions: {
    PullNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        pullNotifications().then(res => {
          if (res.code === 0) {
            const { data } = res
            commit('SET_UNREAD_COUNT', data.unread_count)
            resolve(res)
          } else {
            reject(res)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 设置 是否需要检测需要自动弹出的通知消息 的值
    SetIsNeedCheckAutoAlertNotification({ commit }, isNeedCheckAutoAlertNotification) {
      return new Promise(resolve => {
        commit('SET_IS_NEED_CHECK_AUTO_ALERT_NOTIFICATION', isNeedCheckAutoAlertNotification)
        resolve()
      })
    },

    // 设置 自动弹出的通知消息id 的值
    SetAutoAlertNotificationId({ commit }, autoAlertNotificationId) {
      return new Promise(resolve => {
        commit('SET_AUTO_ALERT_NOTIFICATION_ID', autoAlertNotificationId)
        resolve()
      })
    }
  }
}

export default notification
