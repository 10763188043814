import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: `/login`,
    method: 'post',
    data
  })
}

// 微信二维码登录
export function qrcLogin(data) {
  return request({
    url: `/qrc_login`,
    method: 'post',
    data
  })
}

// 退出
export function logout() {
  return request({
    url: `/logout`,
    method: 'post'
  })
}

// 登录后用户信息
export function findUserInfo() {
  return request({
    url: `/users/info`,
    method: 'get'
  })
}
