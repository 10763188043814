import { message } from 'ant-design-vue'

// 提示信息保留时间间隔
const messageDuration = {
  success: 2,
  error: 5,
  warning: 3,
  info: 2
}

// 弹出提示信息
/**
 * example:
 * {
 *    type: 'error',
 *    content: 'this is message content',
 *    key: ''
 * }
 *
 * 相同的key的信息将被覆盖
 */
export function alertMessage(apiMessage) {
  if (!apiMessage || apiMessage.type === '') {
    return
  }

  const config = {
    content: apiMessage.content,
    key: apiMessage.key,
    duration: messageDuration[apiMessage.type]
  }
  switch (apiMessage.type) {
    case 'success':
      message.success(config)
      break
    case 'error':
      message.error(config)
      break
    case 'info':
      message.info(config)
      break
    case 'warning':
      message.warning(config)
      break
    case 'loading':
      message.loading(config)
      break
  }
}
