<template>
  <div>
    <div class="menus-wrapper">
      <a-spin :spinning="loading">
        <a-layout-header
          :style="{
            maxHeight: '64px',
            borderRadius: '5px'
          }"
        >
          <span v-if="!isShowRemark">{{ subTitle }}</span>
          <span v-else>
            {{ subTitle1 }}
            <a-tooltip v-if="isShowRemarkTip">
              <template slot="title">
                {{ this.data.remark }}
              </template>
              <span>
                备注：{{ this.remark }}。
              </span>
            </a-tooltip>
            <span v-else>备注：{{ this.data.remark }}。</span>
            {{ subTitle2 }}
          </span>

          <div class="iotplt-update-enterprise_qualifications-button">
            <a-space>
              <a-button
                type="primary"
                @click="showEdit"
                v-if="!isShowNew"
              >
                修改资料
              </a-button>
            </a-space>
          </div>

        </a-layout-header>
      </a-spin>
    </div>
    <br>
    <a-layout-content class="content-wrapper">
      <div class="iotplt-content-box">
        <component
          :is="currentComponent"
          :data="data"
          :id="this.registeredAgentId"
          @completed="FindEnterpriseQualification()"
        />
      </div>
    </a-layout-content>
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { FindRegisteredAgentEnterpriseQualification } from '@/api/enterprise_qualification'
import { findRegisteredAgentSale } from '@/api/registered_agent'

export default {
  name: 'RegisteredAgentHome',
  components: {
    NewEnterpriseQualification: () => import('@/views/registered_agent_home/enterprise_qualifications/New'),
    ShowEnterpriseQualification: () => import('@/views/registered_agent_home/enterprise_qualifications/Show')
  },
  data() {
    return {
      currentComponent: 'NewEnterpriseQualification',
      loading: false,
      collapsed: false,
      data: {},
      subTitle: '',
      registeredAgentId: this.$store.getters.userRegisteredAgentId,
      userId: this.$store.getters.userId,
      sale: {},
      isShowRemark: false,
      remark: '',
      isShowRemarkTip: false
    }
  },
  created() {
    this.findRegisteredAgentSale()
    this.FindEnterpriseQualification()
  },
  computed: {
    isShowNew() {
      return this.data.id === 0 && hasPermission('enterprise_qualifications/create')
    }
  },
  methods: {
    showEdit() {
      this.$router.push({ name: 'edit_enterprise_qualification' })
    },

    FindEnterpriseQualification() {
      FindRegisteredAgentEnterpriseQualification({ registered_agent_id: this.registeredAgentId }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          if (this.data.id === 0) {
            this.isShowRemark = false
            this.subTitle = `根据工信部实名制规定，为了保障您的物联网卡使用权益，您需要完成企业资质认证后，才能享有更所平台服务，更多咨询请联系工作人员 ${this.sale.full_name}${this.sale.phone_number}。`
          } else {
            this.currentComponent = 'ShowEnterpriseQualification'
            if (this.data.status === 'notpass') {
              this.isShowRemark = true
              this.subTitle1 = `您的资质认证审核未通过，`
              this.subTitle2 = `您可以修改后重新提交，更多咨询请联系工作人员 ${this.sale.full_name}${this.sale.phone_number}。`
              this.remark = this.data.remark
              if (this.data.remark.length > 20) {
                this.isShowRemarkTip = true
                this.remark = this.data.remark.substring(0, 20) + '...'
              }
            } else {
              this.isShowRemark = false
              this.subTitle = `您已经提交认证资料，当前处于待审核状态，此阶段内，您可以对资料进行修改，更多咨询请联系工作人员 ${this.sale.full_name}${this.sale.phone_number}。`
            }
          }
        }
      })
    },

    // 查找客户的责任销售
    findRegisteredAgentSale() {
      this.loading = true
      findRegisteredAgentSale(this.registeredAgentId).then((res) => {
        if (res.code === 0) {
          this.sale = res.data
          if (this.sale.phone_number === '') {
            this.sale.phone_number = ''
          } else {
            this.sale.phone_number = `(${res.data.phone_number})`
          }
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content-wrapper {
  padding: 0 50px 30px 50px;
  overflow: auto;
  height: calc(100vh - 146px);
}

.menus-wrapper {
  z-index: 1;
  width: 100%;
  padding: 16px 50px 0 50px;
  font-size: 16px;
  .iotplt-update-enterprise_qualifications-button {
    float: right;
  }
}
</style>
