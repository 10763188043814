<template>
  <a-layout>
    <header-navbar />

    <router-view />
  </a-layout>
</template>

<script>

import HeaderNavbar from '@/views/registered_agent_home/RegisteredAgentHeaderNavbar'
export default {
  name: 'RegisteredAgentLayout',
  components: {
    HeaderNavbar
  }
}
</script>
