import Vue from 'vue'

// Numeral.js http://numeraljs.com/
const numeral = require('numeral')

export function formatFloat(value) {
  return numeral(value).format('0.00')
}

export function formatCurrency(value) {
  return numeral(value).format('0,0.00')
}

export function formatBigNumber(value) {
  return numeral(value).format('0,0')
}

// 保留两位小数
Vue.filter('formatFloat', function(value) {
  if (value === '' || value === '待设置') {
    return value
  }

  return formatFloat(value)
})

// 保留两位小数，每三位用逗号分隔
Vue.filter('formatCurrency', function(value) {
  if (value === '' || value === '待设置') {
    return value
  }

  return formatCurrency(value)
})

// 每三位用逗号分隔
Vue.filter('formatBigNumber', function(value) {
  if (value === '') {
    return value
  }

  return formatBigNumber(value)
})
