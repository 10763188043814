import Home from '@/views/home'
import CustomStyleLayout from '@/layouts/CustomStyleLayout'

// 基础路由(不受权限控制的，所有角色都有的路由)
export const constantRouter = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: { title: '欢迎登录' }
  },
  {
    path: '/api_doc',
    name: 'api_doc',
    component: () => import('@/views/api_doc/index.vue'),
    meta: { title: 'API在线文档' }
  },
  {
    path: '/',
    component: CustomStyleLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: { title: ':agent_name', menu: 'home' }
      }
    ]
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/index.vue'),
    meta: { title: '欢迎注册' }
  },
  {
    path: '/qrc_login_callback',
    name: 'qrc_login_callback',
    component: () => import('@/views/login/qrcLoginCallback.vue'),
    meta: { title: '扫码登录' }
  },
  {
    path: '/404',
    component: () => import('@/views/exception/404')
  }
]
