<template>
  <div class="box">
    <a-spin :spinning="loading">
      <div class="card-title">
        流量使用趋势
      </div>

      <div
        ref="flowUsageStatisticsChart"
        class="chart"
      />
    </a-spin>
  </div>
</template>

<script>
import { findHomeFlowUsageStatistics } from '@/api/home'

export default {
  name: 'FlowUsageStatistics',
  data() {
    this.handleWindowResize = this.$lodash.throttle(this.handleWindowResize, 1000)
    return {
      loading: true,
      data: {}, // 图表数据
      chart: null
    }
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    this.fetchData()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  computed: {
    // 指定图表的配置项和数据
    chartOption() {
      return {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '10',
          right: '50',
          bottom: '0',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            name: '月份',
            boundaryGap: false,
            axisTick: {
              alignWithLabel: true
            },
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '单位：GB'
          }
        ],
        series: [
          {
            name: '中国移动',
            type: 'line',
            itemStyle: {
              color: '#1ea3d8'
            },
            data: [],
            areaStyle: {}
          },
          {
            name: '中国联通',
            type: 'line',
            itemStyle: {
              color: '#be1512'
            },
            data: [],
            areaStyle: {}
          },
          {
            name: '中国电信',
            type: 'line',
            itemStyle: {
              color: '#4263ed'
            },
            data: [],
            areaStyle: {}
          }
        ]
      }
    }
  },
  methods: {
    handleWindowResize() {
      this.chart && this.chart.resize()
    },

    // 根据数据绘制图表
    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.flowUsageStatisticsChart)
      this.chartOption.xAxis[0].data = this.$lodash.map(this.data.china_mobile_statistics, 'month')
      this.chartOption.series[0].data = this.$lodash.map(this.data.china_mobile_statistics, 'amount')
      this.chartOption.series[1].data = this.$lodash.map(this.data.china_unicom_statistics, 'amount')
      this.chartOption.series[2].data = this.$lodash.map(this.data.china_telecom_statistics, 'amount')
      this.chart.setOption(this.chartOption)
    },

    fetchData() {
      this.loading = true
      findHomeFlowUsageStatistics(this.id, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.drawChart()
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 300px;
  .card-title {
    color: @primary-color;
    font-size: 16px;
  }

  .chart {
    height: 270px;
  }
}
</style>
