<template>
  <div class="home">
    <a-row :gutter="[16,16]">
      <a-col span="24">
        <div class="iotplt-card-box">
          <finance-statistics />
        </div>
      </a-col>

      <!--      <a-col span="12">-->
      <!--        <div class="iotplt-card-box">-->
      <!--          <recent-notifications />-->
      <!--        </div>-->
      <!--      </a-col>-->
    </a-row>

    <a-row :gutter="[16,16]">
      <a-col span="24">
        <div class="iotplt-card-box">
          <cards-count-statistics />
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="[16,16]">
      <a-col span="12">
        <div class="iotplt-card-box">
          <current-month-operational-cards-statistics />
        </div>
      </a-col>

      <a-col span="12">
        <div class="iotplt-card-box">
          <flow-usage-statistics />
        </div>
      </a-col>
    </a-row>

  </div>
</template>

<script>
import FinanceStatistics from '@/views/home/FinanceStatistics'
import CardsCountStatistics from '@/views/home/CardsCountStatistics'
import CurrentMonthOperationalCardsStatistics from '@/views/home/CurrentMonthOperationalCardsStatistics'
import FlowUsageStatistics from '@/views/home/FlowUsageStatistics'
// import RecentNotifications from '@/views/home/RecentNotifications'
import { findHomeAgentName } from '@/api/agent'
export default {
  name: 'Home',
  components: {
    // RecentNotifications,
    FlowUsageStatistics,
    CurrentMonthOperationalCardsStatistics,
    CardsCountStatistics,
    FinanceStatistics
  },
  computed: {
    agentId() {
      return this.$store.getters.userAgentId
    }
  },
  created() {
    this.setBreadcrumb()
  },
  methods: {
    setBreadcrumb() {
      findHomeAgentName(this.agentId).then((res) => {
        if (res.code === 0) {
          this.$store.dispatch('SetDynamicBreadCrumbsPlaceholder', { ':agent_name': '欢迎您，' + res.data.name })
        }
      })
    }
  }
}
</script>
