<template>
  <a-layout>
    <a-layout-sider
      v-model="collapsed"
      :trigger="null"
      collapsible
      style="background-color:rgb(2, 11, 22)"
    >
      <!-- logo -->
      <left-top-logo />
      <!-- 左侧菜单 -->
      <side-menu />
    </a-layout-sider>

    <a-layout>
      <!-- 顶部菜单 -->
      <header-navbar @change="handleCollapsedChange" />
      <!-- 主体内容 -->
      <content-wrapper />
    </a-layout>
  </a-layout>
</template>

<script>

import LeftTopLogo from '@/components/Logo'
import HeaderNavbar from '@/components/HeaderNavbar'
import ContentWrapper from '@/components/ContentWrapper/index'
import SideMenu from '@/components/SideMenu/index'

export default {
  name: 'BasicLayout',
  components: {
    LeftTopLogo,
    SideMenu,
    HeaderNavbar,
    ContentWrapper
  },
  data() {
    return {
      collapsed: false
    }
  },
  methods: {
    handleCollapsedChange(collapsed) {
      this.collapsed = collapsed
    }
  }
}
</script>

