<!-- 基础layout 由顶部导航栏、菜单栏、主题内容(未定义样式，可自定义内容部分样式) 组成 -->
<!-- 与 BasicLayout 不同在于 主题内容可自定义样式 -->
<template>
  <a-layout>
    <a-layout-sider
      v-model="collapsed"
      :trigger="null"
      collapsible
    >
      <!-- logo -->
      <left-top-logo />
      <!-- 左侧菜单 -->
      <side-menu />
    </a-layout-sider>

    <a-layout>
      <!-- 顶部菜单 -->
      <header-navbar @change="handleCollapsedChange" />
      <!-- 主体内容 -->
      <custom-style-content-wrapper />
    </a-layout>
  </a-layout>
</template>

<script>

import LeftTopLogo from '@/components/Logo'
import HeaderNavbar from '@/components/HeaderNavbar'
import CustomStyleContentWrapper from '@/components/ContentWrapper/CustomStyle'
import SideMenu from '@/components/SideMenu/index'

export default {
  name: 'CustomStyleLayout',
  components: {
    LeftTopLogo,
    SideMenu,
    HeaderNavbar,
    CustomStyleContentWrapper
  },
  data() {
    return {
      collapsed: false
    }
  },
  methods: {
    handleCollapsedChange(collapsed) {
      this.collapsed = collapsed
    }
  }
}
</script>
