import request from '@/utils/request'

// 首页最近通知消息列表
export function findHomeRecentNotifications(params) {
  return request({
    url: `/home/recent_notifications`,
    method: 'get',
    params: params
  })
}

// 首页卡片统计数据
export function findHomeCardsCountStatistics() {
  return request({
    url: `/home/cards_count_statistics`,
    method: 'get'
  })
}

// 首页本月卡运营数据统计数据
export function findHomeCurrentMonthOperationalCardsStatistics() {
  return request({
    url: `/home/current_month_operational_cards_statistics`,
    method: 'get'
  })
}

// 首页流量使用趋势统计数据
export function findHomeFlowUsageStatistics() {
  return request({
    url: `/home/flow_usage_statistics`,
    method: 'get'
  })
}

// 首页财务信息
export function findHomeFinanceStatistics() {
  return request({
    url: `/home/finance_statistics`,
    method: 'get'
  })
}
