<template>
  <a-layout-header class="header-wrapper">
    <div class="logo-wrapper">
      <router-link to="/registered_agents/home">
        <div class="logo">
          <div class="logo-icon">
            <img
              src="@/assets/images/logo_icon.svg"
              alt="logo"
            >
          </div>
          <div class="logo-words">
            物联卡管理平台
          </div>
        </div>
      </router-link>
    </div>

    <div class="agent-name-wrapper">
      <h1 class="iotplt-agent-name">
        {{ registeredAgentName }}
      </h1>
    </div>

    <!-- 右侧操作链接 -->
    <div class="right-items-wrapper">
      <div class="links">
        <ul>
          <li v-if="isSwitchedUser">
            <a-tooltip>
              <template slot="title">
                返回初始账号
              </template>
              <a @click="switchBack">
                <a-icon type="undo" />
              </a>
            </a-tooltip>
          </li>
        </ul>
      </div>
      <div>
        <a-dropdown placement="bottomRight">
          <a-space class="iotplt-cursor-pointer">
            <a-avatar icon="user" />
            <div class="user-full-name">
              {{ this.$store.getters.userFullName }}
            </div>
          </a-space>
          <a-menu slot="overlay">
            <a-menu-item
              key="info"
            >
              <router-link :to="{ name: 'registered_agent_user_info', params: { id: this.$store.getters.userId }}">
                <a-space>
                  <a-icon type="user" />个人资料
                </a-space>
              </router-link>
            </a-menu-item>
            <a-menu-item
              key="logout"
              @click="handleLogout"
            >
              <a-space>
                <a-icon type="logout" />
                退出
              </a-space>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
  </a-layout-header>
</template>

<script>
import { getOriginalUserUid, removeOriginalUserUid } from '@/utils/token'
import { switchBack } from '@/api/switch_user'
export default {
  name: 'RegisteredAgentHeaderNavbar',
  data() {
    return {
      userId: this.$store.getters.userId,
      registeredAgentName: this.$store.getters.registeredAgentName
    }
  },
  computed: {
    isSwitchedUser() {
      return getOriginalUserUid()
    }
  },
  methods: {
    handleLogout() {
      return this.$store.dispatch('Logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    },

    // 切回原账号登录
    switchBack() {
      const originalUserUid = getOriginalUserUid()
      if (!originalUserUid) {
        window.location.reload()
        return
      }

      switchBack({ original_user_uid: getOriginalUserUid() }).then((res) => {
        if (res.code === 0) {
          location.href = res.data.redirect_url
        } else {
          removeOriginalUserUid()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.header-wrapper {
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 4px 24px 0 #22292f1a;
  -webkit-box-shadow: 0 4px 24px 0 #22292f1a;

  // logo 区域
  .logo-wrapper {
    height: 64px;
    overflow: hidden;
    line-height: 64px;
    flex-shrink: 0;
    width: 200px;

    .logo {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-evenly;
      align-items: center;

      .logo-words {
        color: @primary-color;
        font-size: 19px;
        &:hover {
          color: @primary-color;
        }
      }
    }
  }

  .agent-name-wrapper {
    margin-left: 20px;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    .iotplt-agent-name {
      height: 100%;
      color: @primary-color;
    }
  }
  .right-items-wrapper {
    display: flex;
    flex-shrink: 0;
    .user-full-name {
      overflow: hidden;
      max-width: 200px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical
    }

    .links {
      margin-right: 10px;

      ul {
        display: flex;
      }

      li {
        position: relative;
        display: inline-flex;
        width: 50px;
        height: 64px;
        overflow: hidden;

        a {
          display: block;
          width: 100%;
          text-align: center;
          padding: 5px 0;
        }

        i {
          color: #b1b0b0;
          font-size: 25px;
          cursor: pointer;
        }

        &:hover {
          background-color: @primary-color;

          i {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
