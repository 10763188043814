<template>
  <top-agent-side-menu v-if="this.$store.getters.userRole === 'top_agent'" />
  <agency-side-menu v-else-if="this.$store.getters.userRole === 'agency'" />
  <merchant-side-menu v-else-if="this.$store.getters.userRole === 'merchant'" />
  <open-user-side-menu v-else-if="this.$store.getters.userRole === 'open_user'" />
</template>

<script>
import AgencySideMenu from '@/components/SideMenu/Agency'
import TopAgentSideMenu from '@/components/SideMenu/TopAgent'
import MerchantSideMenu from '@/components/SideMenu/Merchant'
import OpenUserSideMenu from '@/components/SideMenu/OpenUser'

export default {
  name: 'SideMenu',
  components: {
    AgencySideMenu,
    TopAgentSideMenu,
    MerchantSideMenu,
    OpenUserSideMenu
  }
}
</script>
