import request from '@/utils/request'

// 注册用户
export function createRegisteredAgent(data) {
  return request({
    url: `/registered_agents`,
    method: 'post',
    data
  })
}

// 获取注册分销商的责任销售
export function findRegisteredAgentSale(id) {
  return request({
    url: `/registered_agents/${id}/sale`,
    method: 'get'
  })
}
