import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lodash from 'lodash'
import moment from 'moment'
import VueClipboard from 'vue-clipboard2'

import './permission'
import '@/assets/styles/global.less'
import '@/assets/styles/wang_editor_text.less'
import '@/core/lazy_use' // 按需加载
import '@/utils/them/them.less'
import './utils/filter'

Vue.use(VueClipboard)

Vue.prototype.$lodash = lodash
Vue.prototype.$moment = moment
Vue.prototype.$pullNotifications = lodash.throttle(function() {
  if (document.hidden) {
    // 离开当前 tab 时不获取
    return
  }
  return store.dispatch('PullNotifications')
}, 2000)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
