<template>
  <a-layout-content class="content-wrapper">
    <bread-crumb />
    <div class="iotplt-content-box">
      <router-view />
    </div>
  </a-layout-content>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'

export default {
  name: 'ContentWrapper',
  components: { BreadCrumb }
}
</script>

<style lang="less" scoped>
.content-wrapper {
  padding: 16px 16px 30px;
  overflow: auto;
  height: calc(100vh - 64px);
  flex: none;
}
</style>
