import request from '@/utils/request'

// 全局搜索
export function globalSearch(key) {
  return request({
    url: `/search`,
    method: 'get',
    params: {
      key: key
    }
  })
}
